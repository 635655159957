import { defineComponent } from 'vue';
import './index.less';

export default defineComponent({
  name: 'PageView',
  render() {
    return (
      <div class="content-wrap page-view">
        <router-view class="body" />
      </div>
    );
  },
});
