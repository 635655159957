import { PageView, AdminLayout } from '@/layouts';

export default [
  {
    path: '/',
    component: AdminLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        // name: '/home',
        component: () => import('@/views/home'),
        meta: { title: '首页' },
        title: '首页',
      },
      {
        path: '/about',
        // name: '/home',
        component: () => import('@/views/about'),
        meta: { title: '关于' },
        title: '关于',
      },
      // {
      //   path: '/junfengAI',
      //   // name: '/product/junfengAI',
      //   component: () => import('@/views/product/junfengAI'),
      //   meta: { title: '君峰AI' },
      //   title: '君峰AI',
      // },
    ],
  },
  // 单页面
  {
    path: '/page',
    redirect: '/page/peopleSearch',
    component: PageView,
    children: [
      // {
      //   path: 'peopleSearch',
      //   component: () => import('@/views/portrait/peopleSearch'),
      //   meta: { title: '人员搜索' },
      //   title: '人员搜索',
      // },
      // {
      //   path: 'holographicArchives',
      //   component: () => import('@/views/portrait/holographicArchives'),
      //   meta: { title: '全息档案' },
      //   title: '全息档案',
      // },
      // {
      //   path: 'similarityAnalysis',
      //   component: () => import('@/views/portrait/similarityAnalysis'),
      //   meta: { title: '相似度分析' },
      //   title: '相似度分析',
      // },
    ],
  },
];
