import GlobalFooter from '@/components/GlobalFooter';
import SystemHead from '@/components/SystemHead';

import { defineComponent } from 'vue';
import './index.less';

export default defineComponent({
  name: 'AdminLayout',
  data() {
    return {};
  },

  render() {
    return (
      <>
        <SystemHead />
        <div class="content-wrap">
          {/* <MultiTab defaultRoute={this.$route} /> */}
          <div class="body">
            <router-view key={this.$route.fullPath} />
          </div>
        </div>
        <GlobalFooter class="page-footer " />
      </>
    );
  },
});
