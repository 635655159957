import { defineComponent } from 'vue';
import './index.less';
import config from '@/config';

export default defineComponent({
  render() {
    return (
      <div class="global-footer">
        {/* <div class="links">
          <a
            href="https://juejin.cn/column/7131913396370276382"
            target="_blank"
          >
            Blog
          </a>

          <a href="https://github.com/fhtwl/vue3-ts-antd-admin" target="_blank">
            GitHub
          </a>

          <a href="https://github.com/fhtwl" target="_blank">
            @Fhtwl
          </a>
        </div> */}
        <div class="copyright">
          <span class="label">联系方式</span>{' '}
          <span class="email value">
            <mail-outlined /> {config.email.join(' ')}
          </span>{' '}
          <span class="phone value">
            <phone-outlined /> {config.phone.join(' ')}
          </span>
        </div>
        <div class="copyright">
          Copyright &copy; 2023 {config.abbreviation} 备案号:
          <a href="https://beian.miit.gov.cn/"> {config.beian}</a>
        </div>
      </div>
    );
  },
});
