import { createRouter, createWebHashHistory } from 'vue-router';

import common from './modules/common';
import website from './modules/website';

const routes = [...common, ...website];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
