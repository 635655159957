import { computed, defineComponent, ref } from 'vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import './index.less';

import similarityAnalysis from '@/assets/images/system/similarity-analysis.png';
import config from '@/config';
import { UserOutlined } from '@ant-design/icons-vue';
// import holographicArchives from "@/assets/images/system/holographic-archives.png"

export default defineComponent({
  setup() {
    const selectedKeys = ref([window.location.hash.replace('#', '')]);

    const handleFullscreen = () => {
      document.body.requestFullscreen();
    };
    const handleFullscreenExit = () => {
      document.exitFullscreen();
    };
    const list = computed(() => {
      return [
        {
          icon: <home-outlined class="icon" />,
          title: '首页',
          url: '/home',
          key: '/home',
        },
        // {
        //   icon: <img src={similarityAnalysis} class="icon" alt="" srcset="" />,
        //   title: '君峰AI',
        //   url: '/junfengAi',
        //   key: '/junfengAi',
        // },
        {
          icon: <info-circle-outlined />,
          title: '关于',
          url: '/about',
          key: '/about',
        },
        // {
        //   icon: <UserOutlined />,
        //   title: '登录',
        //   url: '/auth/login',
        //   key: '/auth/login',
        // },
      ];
    });
    return {
      selectedKeys,
      handleFullscreen,
      handleFullscreenExit,
      list,
    };
  },

  watch: {
    $route: function (newVal: RouteLocationNormalizedLoaded) {
      this.selectedKeys = [newVal.fullPath];
      // console.log(newVal, this.selectedKeys, this.list);
    },
  },
  methods: {
    renderMenuItem(node: {
      icon: JSX.Element;
      title: string;
      url: string;
      key: string;
    }) {
      return (
        <a-menu-item title={node.title} key={node.key}>
          {/* <c-icon class="icon" type={node.icon} /> */}
          {node.icon}
          <router-link class="title" to={node.url}>
            {node.title}
          </router-link>
        </a-menu-item>
      );
    },
  },
  render() {
    const { list, renderMenuItem, handleFullscreen, handleFullscreenExit } =
      this;
    return (
      <div class="system-head">
        <div class="head">
          <div class="logo">
            <div class="logo-img">
              <img src="./logo.svg" alt="" />
            </div>
            {config.title}
          </div>

          <div class="right">
            {/* <fullscreen-outlined
              onClick={handleFullscreen}
              class="fullscreen"
            /> */}
            {/* <FullScreen
              onExit={handleFullscreenExit}
              onFullscreen={handleFullscreen}
              class="fullscreen"
            /> */}
            <div class="menu">
              <a-menu
                v-model:selectedKeys={this.selectedKeys}
                mode="horizontal"
                theme="dark"
              >
                {list.map((item) => renderMenuItem(item))}
              </a-menu>
            </div>
          </div>
        </div>
      </div>
    );
  },
});
